import Airtable from "airtable";

// Initialize Airtable
const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

// Define table instances
const tables = {
  users: base("users"),
  students: base("students"),
  teams: base("teams"),
  events: base("challenge_events"),
  schools: base("schools"),
};

// Airtable utility functions
const fetchAllRecords = async (
  table,
  { filter = "", sort = [], view = "" } = {}
) => {
  try {
    const queryOptions = {};

    // Add filter formula if provided
    if (filter) {
      queryOptions.filterByFormula = filter;
    }

    // Add sort options if provided
    if (sort.length > 0) {
      queryOptions.sort = sort;
    }

    // Add view if provided
    if (view) {
      queryOptions.view = view;
    }

    // Fetch records
    const records = await tables[table].select(queryOptions).all();
    return records.map((record) => ({
      id: record.id,
      data: record.fields, // Map fields to data
    }));
  } catch (error) {
    console.error(`Error fetching records from table "${table}":`, error);
    throw error;
  }
};

const fetchRecord = async (table, recordId) => {
  try {
    const record = await tables[table].find(recordId);
    return {
      id: record.id,
      data: record.fields, // Map fields to data
    };
  } catch (error) {
    console.error(
      `Error fetching record with ID "${recordId}" from table "${table}":`,
      error
    );
    throw error;
  }
};

const createRecord = async (table, fields) => {
  try {
    const createdRecord = await tables[table].create(fields);
    return { id: createdRecord.id, data: createdRecord.fields }; // Map fields to data
  } catch (error) {
    console.error(`Error creating record in table "${table}":`, error);
    throw error;
  }
};

const createRecords = async (table, records) => {
  try {
    // Loop through each record, if there is already a "fields" key, then use record as is, however, if there is no "fields" key, then create a new object with the record as the value of the "fields" key
    const fieldRecords = records.map((record) => {
      if (record.fields) {
        return record;
      } else {
        return { fields: record };
      }
    });
    const createdRecords = await tables[table].create(fieldRecords);
    return createdRecords.map((record) => ({
      id: record.id,
      data: record.fields, // Map fields to data
    }));
  } catch (error) {
    console.error(`Error creating records in table "${table}":`, error);
    throw error;
  }
};

const updateRecord = async (table, recordId, fields) => {
  try {
    const updatedRecord = await tables[table].update(recordId, fields);
    return { id: updatedRecord.id, data: updatedRecord.fields }; // Map fields to data
  } catch (error) {
    console.error(`Error updating record in table "${table}":`, error);
    throw error;
  }
};

const deleteRecord = async (table, recordId) => {
  try {
    await tables[table].destroy(recordId);
    return true;
  } catch (error) {
    console.error(`Error deleting record in table "${table}":`, error);
    throw error;
  }
};

// Helper functions
const getUserByEmail = async (email) => {
  try {
    const filter = `SEARCH("${email}", {email})`;
    const records = await fetchAllRecords("users", { filter });
    return records.length > 0 ? records[0] : null;
  } catch (error) {
    console.error("Error fetching user by email from Airtable:", error);
    throw error;
  }
};

const countTeamsForUserAndEvent = async (userId, eventId) => {
  try {
    const filterFormula = `AND(
      FIND("${userId}", ARRAYJOIN(rollup_team_leader_id, ",")),
      FIND("${eventId}", ARRAYJOIN(rollup_registered_event_id, ",")),
      {kit_requested} = 1
    )`;

    const records = await fetchAllRecords("teams", { filter: filterFormula });
    return records.length;
  } catch (error) {
    console.error("Error counting teams for user and event:", error);
    return 0;
  }
};

export {
  tables,
  fetchAllRecords,
  fetchRecord,
  createRecord,
  createRecords,
  updateRecord,
  deleteRecord,
  getUserByEmail,
  countTeamsForUserAndEvent,
};
