import React from "react";
import { Box, Grid2 as Grid, Typography, TextField } from "@mui/material";

const TeamInformation = ({ register, errors }) => {
  return (
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        mb={2}
        color={"secondary"}
      >
        Project Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={12}>
          <TextField
            label="Project Name"
            fullWidth
            {...register("team_name", {
              validate: (value) => {
                if (!value) return true; // No validation needed if empty
                if (value.length < 5) return "Project name is too short.";
                // Limit project name to 15 words by splitting the string by space
                const words = value.split(" ");
                if (words.length > 15) return "Project name is too long.";
              },
            })}
            error={!!errors.team_name}
            helperText={
              errors.team_name?.message ||
              "A project title is required for the competition, but can be added or updated later."
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamInformation;
