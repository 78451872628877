import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import EventRegistrationPage from "pages/EventRegistration/EventRegistrationPage";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/robotics/register" />} />
        <Route
          path="/event/:eventID/register"
          element={<EventRegistrationPage />}
        />
        <Route path="/robotics/register" element={<EventRegistrationPage />} />
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
