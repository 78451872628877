export const validateFirstName = (value) => {
  if (!value) {
    return "First Name is required";
  }
  if (value.length < 2) {
    return "First Name must be at least 2 characters";
  }
  return "";
};

export const validateLastName = (value) => {
  if (!value) {
    return "Last Name is required";
  }
  if (value.length < 2) {
    return "Last Name must be at least 2 characters";
  }
  return "";
};

export const validateAndCapitalizeFirstName = (value) => {
  if (!value) return { error: "First Name is required.", data: "" };
  if (value.length < 2)
    return { error: "First Name must be at least 2 characters.", data: value };

  // Auto-capitalize the first letter
  const formattedValue =
    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return { error: "", data: formattedValue };
};

export const validateAndCapitalizeLastName = (value) => {
  if (!value) return { error: "Last Name is required.", data: "" };
  if (value.length < 2)
    return { error: "Last Name must be at least 2 characters.", data: value };

  // Auto-capitalize the first letter
  const formattedValue =
    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return { error: "", data: formattedValue };
};

export const validatePhoneNumber = (value) => {
  // Remove all non-numeric characters
  let cleaned = value.replace(/\D/g, "");
  if (!cleaned) {
    return "Phone Number is required";
  }
  if (cleaned.length < 10) {
    return "Phone Number must be at least 10 digits";
  }
  return "";
};
