// Phone Number Formatter Function
export const formatPhoneNumber = (value) => {
  // Remove all non-numeric characters
  let cleaned = value.replace(/\D/g, "");

  // Restrict the length to 10 digits
  cleaned = cleaned.slice(0, 10);

  // Format the phone number as (XXX) XXX-XXXX
  if (cleaned.length > 6) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
      6
    )}`;
  } else if (cleaned.length > 3) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else if (cleaned.length > 0) {
    return `(${cleaned}`;
  } else {
    return "";
  }
};

export const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
